import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Views
import Form from './views/Form';
import Home from './views/Home';

//Layout
import Layout from './layout/Main';

//Theme
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { indigo, blue } from '@mui/material/colors';

//Location provider
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';

const theme = createTheme({
    palette: {
        primary: {
            main: blue[300],
        },
        secondary: {
            main: indigo[300],
        },
    },
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={es}
            >
                <Router>
                    <Routes>
                        <Route element={<Layout />}>
                            <Route exact path="/:token" element={<Form />} />
                            <Route exact path="*" element={<Home />} />
                        </Route>
                    </Routes>
                </Router>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default App;
