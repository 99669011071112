import React from 'react';
import { Box, Typography } from '@mui/material';

import { useTheme } from '@mui/styles';

const Home = () => {
    const theme = useTheme();

    const styles = {
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            background: theme.palette.background.paper,
            borderRadius: 10,
            padding: 20,
            maxHeight: 400,
        },
        h1: {
            fontWeight: 600,
        },
        h5: {
            fontWeight: 500,
        },
        credit: {
            display: 'flex',
            flexDirection: 'row-reverse',
            width: '100%',
        },
    };

    return (
        <Box style={styles.root}>
            <Typography variant="h1" color="primary" style={styles.h1}>
                Score de Crédito
            </Typography>
            <Typography variant="h5" color="primary" style={styles.h5}>
                Consultas de score de crédito
            </Typography>
            <Box mt={3} style={styles.credit}>
                <Typography px={5}>AN ELLYONSOFT INC. COMPANY</Typography>
            </Box>
        </Box>
    );
};

export default Home;
