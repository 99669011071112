import React, { Fragment } from 'react';
import spinner from './spinner.gif';

const Spinner = ({ ...rest }) => {
    return (
        <div>
            <Fragment>
                <img src={spinner} alt="loading..." {...rest} />
            </Fragment>
        </div>
    );
};

export default Spinner;
