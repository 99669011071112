import React, { useRef, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Box,
    Checkbox,
    Typography,
    FormControlLabel,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import RfcFacil from 'rfc-facil';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';
import Spinner from '../components/Spinner';
import ConfirmationModal from '../components/Confirm';
import { HEADERS } from '../constants/headers';
import api from '../constants/api';

const ESTADOS_MEXICO = [
    {
        name: 'Aguascalientes',
        value: 'AGU',
    },
    {
        name: 'Baja California',
        value: 'BCN',
    },
    {
        name: 'Baja California SUR',
        value: 'BCS',
    },
    {
        name: 'Campeche',
        value: 'CAM',
    },
    {
        name: 'Chiapas',
        value: 'CHP',
    },
    {
        name: 'Chihuahua',
        value: 'CHH',
    },
    {
        name: 'Ciudad de México',
        value: 'CMX',
    },
    {
        name: 'Coahuila de Zaragoza',
        value: 'COA',
    },
    {
        name: 'Colima',
        value: 'COL',
    },
    {
        name: 'Durango',
        value: 'DUR',
    },
    {
        name: 'Guanajuato',
        value: 'GUA',
    },
    {
        name: 'Guerrero',
        value: 'GRO',
    },
    {
        name: 'Hidalgo',
        value: 'HID',
    },
    {
        name: 'Jalisco',
        value: 'JAL',
    },
    {
        name: 'México',
        value: 'MEX',
    },
    {
        name: 'Michoacán de Ocampo',
        value: 'MIC',
    },
    {
        name: 'Morelos',
        value: 'MOR',
    },
    {
        name: 'Nayarit',
        value: 'NAY',
    },
    {
        name: 'Nuevo León',
        value: 'NLE',
    },
    {
        name: 'Oaxaca',
        value: 'OAX',
    },
    {
        name: 'Puebla',
        value: 'PUE',
    },
    {
        name: 'Querétaro',
        value: 'QUE',
    },
    {
        name: 'Quintana Roo',
        value: 'ROO',
    },
    {
        name: 'San Luis Potosí',
        value: 'SLP',
    },
    {
        name: 'Sinaloa',
        value: 'SIN',
    },
    {
        name: 'Sonora',
        value: 'SON',
    },
    {
        name: 'Tabasco',
        value: 'TAB',
    },
    {
        name: 'Tamaulipas',
        value: 'TAM',
    },
    {
        name: 'Tlaxcala',
        value: 'TLA',
    },
    {
        name: 'Veracruz',
        value: 'VER',
    },
    {
        name: 'Yucatán',
        value: 'YUC',
    },
    {
        name: 'Zacatecas',
        value: 'ZAC',
    },
];

const Form = () => {
    const route = useParams();

    const theme = useTheme();

    const styles = {
        btn: {
            color: '#fff',
        },
        root: {
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: theme.palette.background.paper,
            borderRadius: 10,
        },
        h3: {
            fontWeight: 500,
            backgroundColor: theme.palette.background.paper,
            padding: 20,
            borderRadius: 10,
            minWidth: '50vw',
            display: 'flex',
            justifyContent: 'center',
        },
        canvas: {
            width: 300,
            height: 100,
        },
        card: {
            backgroundColor: '#ffffffef',
            overflow: 'scroll',
        },
        cardMessage: {
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 10,
        },
        legend: {
            marginTop: '2em',
            fontSize: 8,
            lineHeight: 1.3,
            textAlign: 'justify',
            textJustify: 'inner-word',
            color: theme.palette.grey[600],
        },
        loading: {},
        loadingBox: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.paper,
            padding: 20,
            borderRadius: 10,
        },
    };

    const [isAccepted, setAccepted] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [RFC, setRFC] = useState('');

    const ref = useRef(null);

    const [values, setValues] = useState({
        firstName: '',
        middleName: '',
        firstLastName: '',
        secondLastName: '',
        email: '',
        phone: '',
        accountType: 'PF',
        birthdate: moment().format(),
        city: '',
        street: '',
        adressNumber: '',
        colony: '',
        state: '',
        zipCode: '',
        address: '',
        sign: '',
        rfc: '',
    });

    const [errors, setErrors] = useState({
        firstName: '',
        middleName: '',
        firstLastName: '',
        secondLastName: '',
        email: '',
        phone: '',
        city: '',
        street: '',
        adressNumber: '',
        colony: '',
        birthdate: '',
        state: '',
        zipCode: '',
        sign: '',
    });

    const justLetters = (value) => {
        let regex = new RegExp(
            /^[ña-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F\s]*$/
        );
        return regex.test(value);
    };

    const justNmbers = (value) => {
        let regex = new RegExp(/^[0-9]*$/);
        return regex.test(value);
    };

    const isPhone = (value) => {
        let regex = new RegExp(/^[0-9]{0,10}$/);
        return regex.test(value);
    };

    const isEmail = (value) => {
        //eslint-disable-next-line
        let regex = new RegExp(/^[\w-\.\ñ]+@([\w-]+\.)+[\w-]{2,4}$/);
        return regex.test(value);
    };

    const handleChange = (e) =>
        setValues({ ...values, [e.target.name]: e.target.value });

    const validateData = (data) => {
        let err = {
            firstName: '',
            middleName: '',
            firstLastName: '',
            secondLastName: '',
            email: '',
            phone: '',
            street: '',
            adressNumber: '',
            colony: '',
            birthdate: '',
            zipCode: '',
            city: '',
            state: '',
            sign: '',
        };

        let status = true;

        if (!data.firstName) {
            err.firstName = 'Por favor ingresa tu nombre';
            status = false;
        }
        if (!data.firstLastName) {
            err.firstLastName = 'Por favor ingresa tu apellido';
            status = false;
        }
        if (!data.phone) {
            err.phone = 'Por favor ingresa tu teléfono';
            status = false;
        }
        if (!data.email) {
            err.email = 'Por favor ingresa tu correo';
            status = false;
        }
        if (moment().diff(moment(data.birthdate), 'years') < 18) {
            err.birthdate = 'Usuario no mayor a 18 años';
            status = false;
        }
        if (moment().diff(moment(data.birthdate), 'years') > 100) {
            err.birthdate = 'Comprueba el año de tu fecha de nacimiento (YYYY)';
            status = false;
        }
        if (!data.street) {
            err.street = 'Por favor ingresa tu calle';
            status = false;
        }
        if (!data.adressNumber) {
            err.adressNumber = 'Por favor ingresa tu número interior/exterior';
            status = false;
        }
        if (!data.colony) {
            err.colony = 'Por favor ingresa tu colonia';
            status = false;
        }
        if (!data.zipCode) {
            err.zipCode = 'Por favor ingresa tu código postal';
            status = false;
        }
        if (!data.city) {
            err.city = 'Por favor ingresa tu ciudad';
            status = false;
        }
        if (!data.state) {
            err.state = 'Por favor elige tu estado';
            status = false;
        }
        if (!justLetters(data.firstName)) {
            err.firstName = 'Por favor ingresa un nombre válido';
            status = false;
        }
        if (!justLetters(data.middleName)) {
            err.middleName = 'Por favor ingresa un nombre válido';
            status = false;
        }
        if (!justLetters(data.firstLastName)) {
            err.firstLastName = 'Por favor ingresa un apellido válido';
            status = false;
        }
        if (!justLetters(data.secondLastName)) {
            err.secondLastName = 'Por favor ingresa un apellido válido';
            status = false;
        }

        if (!isEmail(data.email)) {
            err.email = 'Por favor ingresa un correo válido';
            status = false;
        }
        if (!data.sign) {
            err.sign = 'Por favor ingresa tu firma';
            status = false;
        }

        setErrors(err);
        return status;
    };

    const convertToFile = (url, fileName) => {
        let arr = url.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
    };

    const getRFC = () => {
        const birthday = moment(values.birthdate).format('l');
        const desc = birthday.split('/');

        const rfc = RfcFacil.forNaturalPerson({
            name: `${values.firstName} ${values.middleName}`,
            firstLastName: values.firstLastName,
            secondLastName: values.secondLastName,
            day: parseInt(desc[0]),
            month: parseInt(desc[1]),
            year: parseInt(desc[2]),
        });

        setRFC(rfc);
    };

    const handleSend = async () => {
        setLoading(true);

        if (validateData(values)) {
            try {
                const { token } = route;
                let firma = values.sign;
                delete values.sign;

                let sendValues = { ...values };
                delete sendValues.sign;

                sendValues.address = `${sendValues.street} ${
                    sendValues.adressNumber
                }, ${
                    sendValues.colony.charAt(0).toUpperCase() +
                    sendValues.colony.slice(1)
                },  ${sendValues.city},  ${sendValues.state},  ${sendValues.zipCode}`;

                delete sendValues.street;
                delete sendValues.adressNumber;
                delete sendValues.colony;

                if (!values.secondLastName)
                    sendValues.secondLastName = 'NO PROPORCIONADO';

                let profile = {};

                try {
                    profile = await axios.post(
                        'https://app.moffin.mx/api/v1/profiles',
                        { ...sendValues, rfc: RFC },
                        {
                            headers: {
                                Authorization:
                                    'Token ' + process.env.REACT_APP_MOFFIN,
                            },
                        }
                    );
                } catch (error) {
                    setLoading(false);
                    console.log(error);
                    setError(error.response.data.error);
                }

                const file = convertToFile(firma, values.firstName);

                const image = await api.post(
                    `/uploads/publicImage`,
                    {
                        type: file.type,
                        fileName: file.name,
                    },
                    HEADERS()
                );

                await axios.put(image.data.url, file, {
                    headers: { 'Content-Type': file ? file.type : null },
                });

                const leadValues = {
                    creditBureau: {
                        RFC,
                        name: sendValues.firstName,
                        middleName: sendValues.middleName,
                        firstLastName: sendValues.firstLastName,
                        secondLastName: sendValues.secondLastName,
                        email: sendValues.email,
                        birthdate: sendValues.birthdate,
                        address: {
                            city: sendValues.city,
                            state: sendValues.state,
                            zipCode: sendValues.zipCode,
                            address: sendValues.address,
                        },
                        id: profile.data.id,
                        sign: `https://automotive-api.s3.us-east-2.amazonaws.com/${image.data.key}`,
                        isConfirmed: isAccepted,
                    },
                };

                const response = await api.post(
                    `/leads/buro/${token}`,
                    {
                        ...leadValues,
                    },
                    HEADERS()
                );
                const { data } = response;

                setSuccess(data.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(error.response.data.error);
            }
        }
    };

    const handleSign = () => {
        const sign = ref.current.getTrimmedCanvas().toDataURL('image/png');
        setValues({ ...values, sign });
    };

    const ERRORS_DESCRIPTIONS = {
        'Invalid token': 'Su token ha vencido por favor genere una nueva liga',
        'Bad Request':
            'La petición fue rechazada por buró, por favor comprueba tu información o intentalo más tarde',
    };

    if (error)
        return (
            <Box style={styles.cardMessage}>
                <Box variant="h3" color="error" style={styles.h3}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        style={{ width: '100%' }}
                    >
                        <Typography color="error" variant="h3">
                            Hubo un problema al procesar su solicitud
                        </Typography>
                        <Typography color="error" variant="body1">
                            {ERRORS_DESCRIPTIONS[error]
                                ? ERRORS_DESCRIPTIONS[error]
                                : error}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );

    if (success)
        return (
            <Box style={styles.cardMessage}>
                <Typography variant="h3" color="primary" style={styles.h3}>
                    {success}
                </Typography>
            </Box>
        );

    if (loading)
        return (
            <Box style={styles.cardMessage}>
                <Box
                    style={styles.loadingBox}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <center>
                        <Spinner style={styles.loading} />
                    </center>
                    <Typography variant="h3" color="primary" style={styles.h6}>
                        Enviando Informacion
                    </Typography>
                </Box>
            </Box>
        );

    return (
        <Card
            elevation={0}
            sx={{
                ...styles.card,
                width: { xs: '90vw', sm: '50vw', md: '50vw' },
            }}
        >
            <ConfirmationModal
                open={open}
                setOpen={setOpen}
                info={values}
                onSubmit={handleSend}
                RFC={RFC}
                setRFC={setRFC}
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Datos del usuario</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            fullWidth
                            size="small"
                            label="Primer Nombre"
                            error={errors.firstName !== ''}
                            helperText={errors.firstName || null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            name="middleName"
                            value={values.middleName}
                            onChange={handleChange}
                            fullWidth
                            size="small"
                            label="Segundo Nombre"
                            error={errors.middleName !== ''}
                            helperText={errors.middleName || null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            size="small"
                            name="firstLastName"
                            value={values.firstLastName}
                            onChange={handleChange}
                            fullWidth
                            label="Apellido Paterno"
                            error={errors.firstLastName !== ''}
                            helperText={errors.firstLastName || null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            size="small"
                            name="secondLastName"
                            value={values.secondLastName}
                            onChange={handleChange}
                            fullWidth
                            label="Apellido Materno"
                            error={errors.secondLastName !== ''}
                            helperText={errors.secondLastName || null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            name="phone"
                            value={values.phone}
                            onChange={(e) => {
                                if (isPhone(e.target.value)) handleChange(e);
                            }}
                            fullWidth
                            label="Teléfono"
                            error={errors.phone !== ''}
                            helperText={errors.phone || null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            fullWidth
                            label="Correo Eléctronico"
                            error={errors.email !== ''}
                            helperText={errors.email || null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <MobileDatePicker
                            disableFuture
                            label="Fecha de Nacimiento"
                            value={values.birthdate}
                            onChange={(val) => {
                                setValues({
                                    ...values,
                                    birthdate: moment(val).format(),
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    fullWidth
                                    error={errors.birthdate !== ''}
                                    helperText={errors.birthdate || null}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Domicilio</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            name="street"
                            value={values.street}
                            onChange={handleChange}
                            fullWidth
                            label="Calle"
                            error={errors.street !== ''}
                            helperText={errors.street || null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            name="adressNumber"
                            value={values.adressNumber}
                            onChange={(e) => {
                                if (justNmbers(e.target.value)) handleChange(e);
                            }}
                            fullWidth
                            label="Numero interior/exterior"
                            error={errors.adressNumber !== ''}
                            helperText={errors.adressNumber || null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            name="colony"
                            value={values.colony}
                            onChange={handleChange}
                            fullWidth
                            label="Colonia"
                            error={errors.colony !== ''}
                            helperText={errors.colony || null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            name="zipCode"
                            value={values.zipCode}
                            onChange={(e) => {
                                if (justNmbers(e.target.value)) handleChange(e);
                            }}
                            fullWidth
                            label="Código Postal"
                            error={errors.zipCode !== ''}
                            helperText={errors.zipCode || null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            name="state"
                            value={values.state}
                            onChange={handleChange}
                            fullWidth
                            label="Estado"
                            error={errors.state !== ''}
                            helperText={errors.state || null}
                            select
                            SelectProps={{ native: true }}
                            InputLabelProps={{ shrink: true }}
                        >
                            <option value="">Selecciona un estado</option>
                            {ESTADOS_MEXICO.map((item) => (
                                <option value={item.value} key={item.value}>
                                    {item.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            fullWidth
                            label="Ciudad"
                            error={errors.city !== ''}
                            helperText={errors.city || null}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Firma Digital</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={1}>
                            <SignatureCanvas
                                canvasProps={styles.canvas}
                                backgroundColor={'#f5f5f5'}
                                onEnd={handleSign}
                                ref={ref}
                            />
                        </Box>
                        {errors.sign && (
                            <Typography variant="caption" color="error">
                                {errors.sign}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={10}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAccepted}
                                            onClick={(e) => {
                                                setAccepted(!isAccepted);
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography
                                            color="CaptionText"
                                            variant="caption"
                                        >
                                            Autorizo el uso de mecanismos de
                                            consulta digitales, tales como NIP
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Box display="flex" flexDirection="row-reverse">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={styles.btn}
                                        disableElevation
                                        size="large"
                                        onClick={() => {
                                            if (validateData(values)) {
                                                if (values.secondLastName)
                                                    getRFC();
                                                else setRFC('');
                                                setOpen(true);
                                            }
                                        }}
                                        disabled={!isAccepted}
                                    >
                                        Envíar
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <p style={styles.legend}>
                    Por este conducto autorizo expresamente a MOFFIN SOFTWARE
                    SAPI DE CV, para que por conducto de sus funcionarios
                    facultados lleve a cabo Investigaciones, sobre mi
                    comportamiento crediticio o el de la Empresa que represento
                    en Trans Union de México, S. A. SIC y/o Dun & Bradstreet,
                    S.A. SIC Así mismo, declaro que conozco la naturaleza y
                    alcance de la información que se solicitará, del uso que
                    MOFFIN SOFTWARE SAPI DE CV, hará de tal información y de que
                    ésta podrá realizar consultas periódicas sobre mi historial
                    o el de la empresa que represento, consintiendo que esta
                    autorización se encuentre vigente por un período de 3 años
                    contados a partir de su expedición y en todo caso durante el
                    tiempo que se mantenga la relación jurídica.
                </p>
            </CardContent>
        </Card>
    );
};

export default Form;
