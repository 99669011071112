import React, { useState } from 'react';
import {
    Dialog,
    Card,
    Typography,
    CardContent,
    Box,
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TextField,
} from '@mui/material';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const ConfirmationModal = ({
    open,
    setOpen,
    info = {},
    onSubmit = () => {},
    RFC = '',
    setRFC = () => {},
    ...rest
}) => {
    const styles = {
        root: {},
        card: {
            padding: 30,
            width: '80ch',
            overflow: 'scroll',
        },
        head: {
            fontWeight: 700,
            width: '28%',
        },
        text: {
            textTransform: 'capitalize',
        },
        btn: {
            color: '#fff',
        },
    };

    const [error, setError] = useState(null);

    const handleCancel = () => setOpen(false);
    const handleConfirm = () => {
        if (RFC.length !== 13) {
            setError('El RFC debe tener una longitud de 13 caracteres');
        } else {
            setError(null);
            onSubmit();
        }
    };

    return (
        <Dialog
            onClose={(e) => setOpen(false)}
            open={open}
            style={styles.root}
            maxWidth="lg"
            {...rest}
        >
            <Card style={styles.card} data-testid="confirmation-modal">
                <center>
                    <Typography variant="h4" color="textPrimary">
                        Confirmación de Información
                    </Typography>
                </center>
                <CardContent>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={styles.head}>
                                    Nombre
                                </TableCell>
                                <TableCell style={styles.text}>
                                    {`${info.firstName} ${info.middleName} ${info.firstLastName} ${info.secondLastName}`}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={styles.head}>
                                    Teléfono
                                </TableCell>
                                <TableCell>{info.phone}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={styles.head}>
                                    Correo
                                </TableCell>
                                <TableCell>{info.email}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={styles.head}>
                                    Fecha de Nacimiento
                                </TableCell>
                                <TableCell>
                                    {moment(info.birthdate).format(
                                        'DD MMMM YYYY'
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={styles.head}>
                                    Domicilio
                                </TableCell>
                                <TableCell>
                                    {info.street &&
                                        info.adressNumber &&
                                        info.colony &&
                                        info.zipCode &&
                                        info.city &&
                                        info.state &&
                                        `${info.street} ${info.adressNumber}, ${
                                            info.colony
                                                .charAt(0)
                                                .toUpperCase() +
                                            info.colony.slice(1)
                                        }, ${info.city}, ${info.state}, CP: ${info.zipCode}`}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={styles.head}>RFC</TableCell>
                                <TableCell>
                                    <TextField
                                        data-testid="RFC-TextField"
                                        value={RFC}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 13)
                                                setRFC(
                                                    e.target.value.toUpperCase()
                                                );
                                        }}
                                        fullWidth
                                        size="small"
                                        error={error ? true : false}
                                    />
                                    <Typography
                                        variant="caption"
                                        color={error ? 'error' : 'textPrimary'}
                                    >
                                        {error ||
                                            'En caso que tu RFC no coincida checa tu información ingresada modificalo aquí'}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        gap={2}
                        mt={2}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            style={styles.btn}
                            onClick={handleCancel}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            style={styles.btn}
                            onClick={handleConfirm}
                        >
                            Confirmar
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Dialog>
    );
};

export default ConfirmationModal;
